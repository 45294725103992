import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import styled from 'styled-components';

const CustomCalendar = styled(FullCalendar)`
  width: 100%;
  height: 100%;
`;

function App() {
  return (
    <>
      <CustomCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
      />
    </>
  );
}

export default App;
